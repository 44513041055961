import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Card1ColumnItem from "./home/Card1ColumnItem"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"

const Question7 = (props) =>{

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Revision for dislocation")

    const [showModalQ1, setShowModalQ1] = useState(false)
    const [showModalQ2, setShowModalQ2] = useState(false)
    
    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>
            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                    
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>
                
                <Card1ColumnItem onClick={(e)=>{
                    setShowModalQ1(true)
                }} label="First time revision for dislocation/instability" />

                <Card1ColumnItem onClick={(e)=>{
                    setShowModalQ2(true)
                }} label="Re-revision for dislocation/instability" />

                {/* First time revision for dislocation/instability */}
                <Modal show={showModalQ1} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowModalQ1(!showModalQ1)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="2" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, {
                                    "label":"First time revision for dislocation/instability",
                                    "label_slug":"first_time_revision_for_dislocation_or_instability",
                                    "answer":0,
                                    "rhcc":"2"
                                })

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Re-revision for dislocation/instability */}
                <Modal show={showModalQ2} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowModalQ2(!showModalQ2)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="3" />
                        
                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, {
                                    "label":"Re-revision for dislocation/instability",
                                    "label_slug":"_or_instability",
                                    "answer":0,
                                    "rhcc":"3"
                                })
                                
                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

            </CardSection>
        </>
    )

}

export default Question7