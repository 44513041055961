import { useEffect, useState } from "react"
import Card1ColumnItem from "./home/Card1ColumnItem"
import { Modal } from "react-bootstrap"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import QItem from "./home/QItem"
import QCheckbox from "./home/QCheckbox"

const ASAGrade = (props) =>{

    const [currentQuestion, setCurrentQuestion] = useState(0)

    const [currentTitle, setCurrentTitle] = useState("ASA grade")

    const [qHistory, setQHistory] = useState(props.history)

    const [showErrorModal, setShowErrorModal] = useState(false)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    const [asaGradeType, setAsaGradeType] = useState(0)

    return(
        <>
            <BackButton onClick={(e)=>{
                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }
            }} />

            {currentQuestion==0 && (
                <>
                    <CardSection label={currentTitle}>

                        {/* <div onClick={(e)=>{
                                setAsaGradeType(0)
                            }} className="card nopad rhcc-card-column">
                            <div className="card-body">
                                <table className="table nomargin tbl-centered tbl-colored">
                                    <tr>
                                        <td width="100%">
                                            <input checked={asaGradeType==0} onChange={(e)=> setAsaGradeType(0)} type="radio" name="asa_grade" /> ASA 1 or 2
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div onClick={(e)=>{
                                setAsaGradeType(1)
                            }} className="card nopad rhcc-card-column">
                            <div className="card-body">
                                <table className="table nomargin tbl-centered tbl-colored">
                                    <tr>
                                        <td width="100%"><input checked={asaGradeType==1} onChange={(e)=> setAsaGradeType(1)} type="radio" name="asa_grade" /> ASA 3 or 4<sup>1</sup></td>
                                    </tr>
                                </table>
                            </div>
                        </div> */}

                        <div onClick={(e)=> {
                            // click event
                            setAsaGradeType(0)
                        }} className="card">
                            <div className={`qlabel`}>
                                <span className="qtitle">ASA 1 or 2</span>
                            </div>
                            
                            <div className="bhs-buttons chks">
                                <label className={`${asaGradeType==0 ? "active" : ""}`} >
                                    <input onChange={(e)=> {
                                        e.target.checked && asaGradeType(0)
                                    }} checked={asaGradeType==0} type="radio" name="ar" /> <span className="vertical-center">&nbsp;</span>
                                </label>
                            </div>

                        </div>

                        <div onClick={(e)=> {
                            // click event
                            setAsaGradeType(1)
                        }} className="card">
                            <div className={`qlabel`}>
                                <span className="qtitle">ASA 3 or 4<sup>1</sup></span>
                            </div>
                            
                            <div className="bhs-buttons chks">
                                <label className={`${asaGradeType==1 ? "active" : ""}`} >
                                    <input onChange={(e)=> {
                                        e.target.checked && asaGradeType(1)
                                    }} checked={asaGradeType==1} type="radio" name="ar" /> <span className="vertical-center">&nbsp;</span>
                                </label>
                            </div>

                        </div>

                        <p className="asalabel">1 Note that BMI &gt;40 is automatically ASA 3</p>

                        <button onClick={(e)=>{
                            
                            if(asaGradeType==-1){
                                setShowErrorModal(true)
                            }else{
                                props.onNext && props.onNext(asaGradeType)
                            }

                        }} className="btn btn-primary btn-block">Next</button>

                        </CardSection>

                        <div className="card nopad">

                        <div className="card-body nopad colored">
                            
                            <table className="tbl">
                                <tr>
                                    <td className="tth" width="20%"></td>
                                    <td className="tth" width="40%">DEFINITION</td>
                                    <td className="tth" width="40%">ADULT EXAMPLES <br /><span>including, but not limited to</span></td>
                                </tr>
                                
                                <tr>
                                    <td>ASA I</td>
                                    <td>A normal healthy patient</td>
                                    <td>Healthy, non-smoking, no or minimal alcohol use</td>
                                </tr>
                                <tr>
                                    <td>ASA II</td>
                                    <td>A patient with mild systemic disease</td>
                                    <td>Mild diseases only without substantive functional limitations. Current smoker, social alcohol drinker, pregnancy, obesity (30 &lt; BMI &lt; 40), well-controlled DM/HTN, mild lung disease</td>
                                </tr>
                                <tr>
                                    <td>ASA III</td>
                                    <td>A patient with severe systemic disease</td>
                                    <td>Substantive functional limitations; One or more moderate to severe diseases. Poorly controlled DM or HTN, COPD, morbid obesity (BMI&gt;40), active hepatitis, alcohol dependence or abuse, implanted pacemaker, moderate reduction of ejection fraction, ESRD undergoing regularly scheduled dialysis, history (&gt;3 months) of MI, CVA, TIA, or CAD/stents.</td>
                                </tr>
                                <tr>
                                    <td>ASA IV</td>
                                    <td>A patient with severe systemic disease that is a constant threat to life</td>
                                    <td>Recent (&lt;3 months) MI, CVA, TIA or CAD/stents, ongoing cardiac ischemia or severe valve dysfunction, severe reduction of ejection fraction, shock, sepsis, DIC, ARD or ESRD not undergoing regularly scheduled dialysis</td>
                                </tr>
                                
                            </table>
                            
                        </div>

                        </div>
                </>
            )}

            {currentQuestion==1 &&(
                <CardSection label={currentTitle}>
                    <strong className="colored">ASA 1 or 2</strong><br /><br />
					<span className="colored">No change to RHCC</span>
                </CardSection>
            )}

            {currentQuestion==2 &&(
                <CardSection label={currentTitle}>
                    <strong className="colored">ASA 3 or 4</strong><br /><br />
					<span className="colored">Add * to RHCC grade</span>
                </CardSection>
            )}

            <Modal show={showErrorModal} centered className="">
                <Modal.Body>

                    <p>Please select ASA grade</p>

                    <button onClick={(e)=>{
                        setShowErrorModal(false)
                    }} className="btn btn-primary">Okay</button>
                </Modal.Body>
            </Modal>
            
        </>
    )

}

export default ASAGrade