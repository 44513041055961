
const CardSection = (prop) =>{

    return(
        <div className="card nopad">
            <div className="card-header">
                {prop.label || ""}
            </div>
            
            <div className="card-body">
                
                {prop.children}

                {/* <div className="card">
                    <div className="qlabel">
                        <span className="qtitle">Loose socket and bone loss?</span>
                    </div>
                    <div className="bhs-buttons">
                        <label><input type="radio" name="fr" /> <span className="vertical-center">Yes</span></label>
                        <label><input type="radio" name="fr" /> <span className="vertical-center">No</span></label>
                    </div>
                </div> */}
                
            </div>
            
        </div>
    )

}

export default CardSection;