import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import QItem from "./home/QItem"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const Question5 = (props) =>{

    const [quesionOne, setQuestionOne] = useState({
        label: "Femoral and Acetabular fracture?",
        id:51,
        answer:-1
    })

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Acetabular Peri-prosthetic fracture?")

    const [showNoModal, setShowNoModal] = useState(false)
    const [showYesModal, setShowYesModal] = useState(false)

    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>
            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                    
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>
                
                {currentQuestion==0 && (
                    <QItem allowButton={true} label={quesionOne.label} key={quesionOne.id} answer={quesionOne.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            setShowYesModal(true)
                        }else{
                            setShowNoModal(true)
                        }
                    }} />
                )}

                <Modal show={showNoModal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowNoModal(!showNoModal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="3" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, {
                                    "label":"Femoral and Acetabular fracture?",
                                    "label_slug":"acetabular_peri-prosthetic_fracture_present",
                                    "answer":0,
                                    "rhcc":"3"
                                })

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showYesModal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowYesModal(!showYesModal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="3" />
                        <br />
                        <ClassificationTable className="cltable" label="UNIFIED CLASSIFICATION" val="E" />
                        
                        <br />
                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, {
                                    "label":"Femoral and Acetabular fracture?",
                                    "label_slug":"femoral_and_acetabular_fracture",
                                    "answer":1,
                                    "rhcc":"3",
                                    "uc":"E"
                                })
                                
                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

            </CardSection>
        </>
    )

}

export default Question5