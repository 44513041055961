import 'bootstrap/dist/css/bootstrap.min.css';
import { useRef, useState } from 'react';
import './assets/style.css'
import { Modal } from "react-bootstrap"
import QItem from './components/home/QItem';
import Question2 from './components/Question2';
import Question3 from './components/Question3';
import Question4V2 from './components/Question4V2';
import Question5 from './components/Question5';
import Question6 from './components/Question6';
import Question7 from './components/Question7';
import QuestionOne from './components/QuestionOne';
import Report from './components/Report';
import NavigationBar from './NavigationBar';
import { getLastArr } from './components/libs/BackButton';
import ASAGrade from './components/ASAGrade';

function App() {

  const [questions, setQuestions] = useState([
    {
      id:1,
      label:"Acetabular Revision",
      slug:"acetabular_revison",
      reportRHCC:"Acetabular",
      answer:-1
    },
    {
      id:2,
      label:"Femoral Revision",
      slug:"femoral_revision",
      reportRHCC:"Femoral",
      answer:-1
    },
    {
      id:3,
      label:"Infection present",
      slug:"infection_present",
      reportRHCC:"Infection",
      answer:-1
    }
    ,{
      id:4,
      label:"Femoral Peri-prosthetic fracture present",
      slug:"femoral_peri-prosthetic_fracture_present",
      reportRHCC:"Femoral PPF",
      answer:-1
    },{
      id:5,
      label:"Acetabular Peri-prosthetic fracture present",
      slug:"acetabular_peri-prosthetic_fracture_present",
      reportRHCC:"Acetabular PPF",
      answer:-1
    },{
      id:6,
      label:"Soft tissue deficiency",
      slug:"soft_tissue_deficiency",
      reportRHCC:"ST Deficiency",
      answer:-1
    },{
      id:7,
      label:"Revision for Dislocation",
      slug:"revision_for_dislocation",
      reportRHCC:"RF Dislocation",
      answer:-1
    }
  ])

  const [isFirstScreen, setIsFirstScreen] = useState(true)

  const [report, setReport] = useState([])

  const [totalSelectedQuestions, setTotalSelectedQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const [showASAGrade, setShowASAGrade] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const getCurrentQuestion = () =>{
    
    let nextIndex = totalSelectedQuestions.indexOf(currentQuestion)+1;

    if(totalSelectedQuestions[nextIndex]===undefined){
      setShowASAGrade(true)
      return -1
    }

    return totalSelectedQuestions[nextIndex]
  }

  const [errorMessage, setErrorMessage] = useState("")
  const [showErrorModal, setShowErrorModal] = useState(false)

  const [q1History, setQ1History] = useState([])
  const [q2History, setQ2History] = useState([])
  const [q3History, setQ3History] = useState([])
  const [q4History, setQ4History] = useState([])
  const [q5History, setQ5History] = useState([])
  const [q6History, setQ6History] = useState([])
  const [q7History, setQ7History] = useState([])
  const [asaGrade, setAsaGrade] = useState([])

  const [asaGradeType, setAsaGradeType] = useState(0)

  const removeLast = () =>{
    let newReport = [...report]
    newReport.pop()
    setReport(newReport)
  }

  return (
    
    <>
      <NavigationBar />

      <main className="container">
        <div className="bhs-stemp"></div>

        {isFirstScreen ? (
          <>
            <h1>Revision Hip Complexity Classification</h1>

            <div className="info-quote">
              Tick box for type of defect/issues present. Each one takes you to separate flow. Highest score is final score ie if RHCC 3 from any flow overall class is 3.
            </div>

            {questions.map((question, index)=>(
                <QItem allowButton={true} label={question.label} key={`question-${question.id}`} answer={question.answer} onChange={(isChecked)=>{
                  
                  let ques = [...questions]

                  ques[index].answer = isChecked

                  setQuestions(ques)

                }} />
            ))}

            <button onClick={(e)=>{

                let selectedId = []
                setTotalSelectedQuestions([])

                let filtered = questions.filter(emp=>{
                  if(emp.answer==1) selectedId.push(emp.id)
                  return emp.answer==1
                })

                let isSelectAll = questions.filter(emp=>{
                  return emp.answer==1 || emp.answer==0
                })

                if(isSelectAll.length!=7){
                  setErrorMessage("Please answer Yes or No to all defects and issues")
                  setShowErrorModal(true)
                }else if(selectedId.length>0){
                  setTotalSelectedQuestions(selectedId)
  
                  setCurrentQuestion(selectedId[0])

                  setIsFirstScreen(false)
                }else{
                  setErrorMessage("Please answer Yes or No to all defects and issues")
                  setShowErrorModal(true)
                }

                setReport([])

            }}   className="btn btn-primary btn-block">Next</button>
          </>
        ) : (
          <>

          {totalSelectedQuestions.indexOf(1)!=-1 && currentQuestion==1 && ( 
              <QuestionOne history={q1History} onBack={(e)=>{
                setIsFirstScreen(true)
                setShowASAGrade(false)
              }} onEnd={(qHistory, array)=>{

                setQ1History(qHistory)
                
                setReport(prev=>[
                  ...prev, {
                    question:questions[0].label,
                    label_slug:questions[0].slug,
                    questionRhcc:questions[0].reportRHCC,
                    answer:1,
                    sub:array
                  }
                ])

                console.log(array)

                setCurrentQuestion( getCurrentQuestion() )

              }} /> 
          )}
          
          {totalSelectedQuestions.indexOf(2)!=-1 && currentQuestion==2 && (
              <Question2 history={q2History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(2)

                removeLast()


                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, array)=>{
                
                setQ2History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[1].label,
                    label_slug:questions[1].slug,
                    questionRhcc:questions[1].reportRHCC,
                    answer:1,
                    sub:array
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )

              }} />
          )}

          {totalSelectedQuestions.indexOf(3)!=-1 && currentQuestion==3 && (
              <Question3 history={q3History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(3)

                removeLast()

                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, array)=>{
                
                setQ3History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[2].label,
                    label_slug:questions[2].slug,
                    questionRhcc:questions[2].reportRHCC,
                    answer:1,
                    sub:array
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )
              }} />
          )}
          
          {totalSelectedQuestions.indexOf(4)!=-1 && currentQuestion==4 && (
              <Question4V2 history={q4History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(4)

                removeLast()

                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, array)=>{

                setQ4History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[3].label,
                    label_slug:questions[3].slug,
                    questionRhcc:questions[3].reportRHCC,
                    answer:1,
                    sub:array
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )
              }} />
          )}

          {totalSelectedQuestions.indexOf(5)!=-1 && currentQuestion==5 && (
              <Question5 history={q5History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(5)

                removeLast()

                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, object)=>{

                setQ5History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[4].label,
                    label_slug:questions[4].slug,
                    questionRhcc:questions[4].reportRHCC,
                    answer:1,
                    sub:[
                      object
                    ]
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )
              }} />
          )}
          
          {totalSelectedQuestions.indexOf(6)!=-1 && currentQuestion==6 && (
              <Question6 history={q6History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(6)

                removeLast()

                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, object)=>{
                
                setQ6History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[5].label,
                    label_slug:questions[5].slug,
                    questionRhcc:questions[5].reportRHCC,
                    answer:1,
                    sub:[
                      object
                    ]
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )

              }} />
          )}

          {totalSelectedQuestions.indexOf(7)!=-1 && currentQuestion==7 && (
              <Question7 history={q7History} onBack={(e)=>{

                let ciIndex = totalSelectedQuestions.indexOf(7)

                removeLast()

                if(ciIndex>0){
                  setCurrentQuestion( totalSelectedQuestions[ciIndex-1] )
                }else{
                  setIsFirstScreen(true)
                  setShowASAGrade(false)
                }

              }} onEnd={(qHistory, object)=>{

                setQ7History(qHistory)

                setReport(prev=>[
                  ...prev, {
                    question:questions[6].label,
                    label_slug:questions[6].slug,
                    questionRhcc:questions[6].reportRHCC,
                    answer:1,
                    sub:[
                      object
                    ]
                  }
                ])

                setCurrentQuestion( getCurrentQuestion() )

              }} />
          )}
          
          </>
        )}

        {showASAGrade && (
          <ASAGrade asaGradeType={asaGradeType} history={asaGrade} onBack={(e)=>{

            let lastQ = getLastArr(totalSelectedQuestions)

            removeLast()

            if(lastQ==0){
              setIsFirstScreen(true)
              setShowASAGrade(false)
            }else{
              setCurrentQuestion( lastQ )
              setShowASAGrade(false)
            }
          }} onNext={(agt)=>{
            setAsaGradeType(agt)
            setShowASAGrade(false)
            setShowResult(true)
          }} />
        )}

        {showResult && (
          <Report asaGradeType={asaGradeType} onBack={(e)=>{

            setShowASAGrade(true)
            setShowResult(false)

          }} data={report} />
        )}

      </main>

      <Modal show={showErrorModal} centered className="">
          <Modal.Body>

            <p>{errorMessage}</p>

            <button onClick={(e)=>{
                setShowErrorModal(false)
            }} className="btn btn-primary">Okay</button>
          </Modal.Body>
      </Modal>

    </>

  );
}

export default App;
