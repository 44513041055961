const Card2ColumnItem = (props) =>{

    return(
        <div onClick={props.onClick} className="card nopad">
            <div className="card-body">
                <table className="table nomargin tbl-centered tbl-colored">
                    <tr>
                        <td width="50%">{props.label || ""}</td>
                        <td width="50%">{props.label2 || ""}</td>
                    </tr>
                </table>
            </div>
        </div>
    )

}

export default Card2ColumnItem