import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Card1ColumnItem from "./home/Card1ColumnItem"
import QItem from "./home/QItem"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const Question4V2 = (props) =>{

    const [quesionOne, setQuestionOne] = useState({
        label: "GT fracture alone",
        slug:"gt_fracture_alone",
        id:41,
        answer:-1
    })

    const [quesionTwo, setQuestionTwo] = useState({
        label: "Fracture around or at tip of stem",
        slug:"fracture_around_or_at_tip_of_stem",
        id:42,
        answer:-1
    })

    const [quesionThree, setQuestionThree] = useState({
        label: "Knee replacement below fracture",
        slug:"knee_replacement_below_fracture",
        id:43,
        answer:-1
    })

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Femoral Periprosthetic fracture")

    const [showYesmodal, setShowYesModal] = useState(false)

    const [showQ2YesModal, setShowQ2YesModal] = useState(false)
    const [showQ2NoModal, setShowQ2NoModal] = useState(false)

    const [showQ31Modal, setShowQ31Modal] = useState(false)

    const [showQ4YesModal, setShowQ4YesModal] = useState(false)
    const [showQ4NoModal, setShowQ4NoModal] = useState(false)

    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>
            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>

                {currentQuestion==0 && (
                    <QItem allowButton={true} label={quesionOne.label} key={quesionOne.id} answer={quesionOne.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            setShowYesModal(true)
                        }else{
                            // show next question and change the title
                            setCurrentTitle("Fracture around or at tip of stem")
                            setCurrentQuestion(1)
                            setQHistory( pushArr(qHistory, 1) )
                            window.initQuestionOneImagePopup(".popup-btn-qq4")
                        }
                    }} />
                )}

                <Modal show={showYesmodal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowYesModal(!showYesmodal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="A" label2="RHCC" val2="1" />

                        
                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":1,
                                        "uc":"A",
                                        "rhcc":1
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {currentQuestion==1 && (
                    <>
                        <QItem allowButton={true} label={quesionTwo.label} key={quesionTwo.id} answer={quesionTwo.answer} onChange={(isChecked)=>{
                            if(isChecked){
                                // show next question and change the title
                                setCurrentTitle("Stem loose")
                                setCurrentQuestion(2)
                                setQHistory( pushArr(qHistory, 2) )
                                window.initQuestionOneImagePopup(".popup-btn-q4")
                            }else{
                                //setShowQ2NoModal(true)
                                setCurrentTitle("Knee replacement below fracture")
                                setCurrentQuestion(4)
                                setQHistory( pushArr(qHistory, 4) )
                            }
                        }} />
                        
                        <hr />

                        <div className="portfolio-item row">
                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>Stem fixed</label><br />
                                <a href="/images/q44.png" className="fancylight popup-btn-qq4" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q44.png" alt="" />
                                </a>
                            </div>

                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>Stem fixed</label><br />
                                <a href="/images/q45.png" className="fancylight popup-btn-qq4" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q45.png" alt="" />
                                </a>
                            </div>
                        </div>

                    </>
                )}

                {currentQuestion==4 && (
                    <>
                        <QItem allowButton={true} label={quesionThree.label} key={quesionThree.id} answer={quesionThree.answer} onChange={(isChecked)=>{
                            if(isChecked){
                                setShowQ2YesModal(true)
                            }else{
                                setShowQ2NoModal(true)
                            }
                        }} />
                    </>
                )}

                <Modal show={showQ2NoModal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowQ2NoModal(!showQ2NoModal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        <br />
                        <strong>Knee replacement below fracture</strong>
                        
                        <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="C" label2="RHCC" val2="2" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesionTwo.label,
                                        "label_slug":quesionTwo.slug,
                                        "answer":0,
                                        //"uc":"C",
                                        //"rhcc":2
                                    },
                                    {
                                        "label":quesionThree.label,
                                        "label_slug":quesionThree.slug,
                                        "answer":0,
                                        "uc":"C",
                                        "rhcc":2
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showQ2YesModal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowQ2YesModal(!showQ2YesModal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        <br />
                        <strong>Knee replacement below fracture</strong>
                        
                        <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="D" label2="RHCC" val2="2" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesionTwo.label,
                                        "label_slug":quesionTwo.slug,
                                        "answer":1,
                                        //"uc":"C",
                                        //"rhcc":2
                                    },
                                    {
                                        "label":quesionThree.label,
                                        "label_slug":quesionThree.slug,
                                        "answer":1,
                                        "uc":"D",
                                        "rhcc":2
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {currentQuestion==2 && (
                    <>
                        <Card1ColumnItem onClick={(e)=>{
                            setShowQ31Modal(true)
                        }} label="Stem Fixed" />

                        <Card1ColumnItem onClick={(e)=>{
                            // show next question and change the title
                            setCurrentTitle("Bone Loss")
                            setCurrentQuestion(3)
                            setQHistory( pushArr(qHistory, 3) )
                            window.initQuestionOneImagePopup(".popup-btn-q41")
                        }} label="Loose Stem" />
                        
                        <hr />

                        <div className="portfolio-item row">
                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>Stem fixed</label><br />
                                <a href="/images/q41.png" className="fancylight popup-btn-q4" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q41.png" alt="" />
                                </a>
                            </div>
                        </div>

                        {/* Stem Fixed */}
                        <Modal show={showQ31Modal} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowQ31Modal(!showQ31Modal)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="B2" label2="RHCC" val2="2" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":0
                                            },
                                            {
                                                "label":quesionTwo.label,
                                                "label_slug":quesionTwo.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Stem Fixed",
                                                "label_slug":"stem_fixed",
                                                "answer":-1,
                                                "uc":"B2",
                                                "rhcc":2
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </>
                )}

                {currentQuestion==3 && (
                    <>
                        <Card1ColumnItem onClick={(e)=>{
                            setShowQ4YesModal(true)
                        }} label="Bone Loss" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowQ4NoModal(true)
                        }} label="No Bone Loss" />
                        
                        <hr />

                        <div className="portfolio-item row">
                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>Bone Loss</label><br />
                                <a href="/images/q42.png" className="fancylight popup-btn-q41" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q42.png" alt="" />
                                </a>
                            </div>

                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>No Bone Loss</label><br />
                                <a href="/images/q43.png" className="fancylight popup-btn-q41" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q43.png" alt="" />
                                </a>
                            </div>
                        </div>

                        {/* Bone Loss */}
                        <Modal show={showQ4YesModal} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowQ4YesModal(!showQ4YesModal)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="B3" label2="RHCC" val2="3" />

                                
                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":0
                                            },
                                            {
                                                "label":quesionTwo.label,
                                                "label_slug":quesionTwo.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Loose Stem",
                                                "label_slug":"loose_stem",
                                                "answer":-1
                                            },
                                            {
                                                "label":"Bone Loss",
                                                "label_slug":"bone_loss",
                                                "answer":-1,
                                                "uc":"B3",
                                                "rhcc":3
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* No Bone Loss */}
                        <Modal show={showQ4NoModal} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowQ4NoModal(!showQ4NoModal)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="UNIFIED CLASSIFICATION" val="B2" label2="RHCC" val2="2" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":0
                                            },
                                            {
                                                "label":quesionTwo.label,
                                                "label_slug":quesionTwo.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Loose Stem",
                                                "label_slug":"loose_stem",
                                                "answer":-1
                                            },
                                            {
                                                "label":"No Bone Loss",
                                                "label_slug":"no_bone_loss",
                                                "answer":-1,
                                                "uc":"B2",
                                                "rhcc":2
                                            }
                                        ])
                                        
                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </>
                )}

                </CardSection>
        </>
    )

}

export default Question4V2