import { useEffect, useImperativeHandle, useState } from "react"
import { Modal } from "react-bootstrap"
import Card1ColumnItem from "./home/Card1ColumnItem"
import Card2ColumnItem from "./home/Card2ColumnItem"
import Card3ColumnItem from "./home/Card3ColumnItem"
import QItem from "./home/QItem"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const QuestionOne = (props) =>{

    const handleBackword = () =>{
        alert("calling...")
    }

    const [quesionOne, setQuestionOne] = useState({
        label: "Loose socket and bone loss?",
        slug:"loose_socket_and_bone_loss",
        id:11,
        answer:-1
    })

    const [quesionTwo, setQuesionTwo] = useState({
        label: "Removal of well-fixed uncemened socket with >1 cm lysis ischium or pubic rams?",
        slug:"removal_of_well-fixed_uncemened_socket_with_lessthan_1_cm_lysis_ischium_or_pubic_rams",
        id:1101,
        answer:-1
    })

    const [quesionThree, setQuesionThree] = useState({
        label: "Lateral to ilioischial line",
        slug:"lateral_to_ilioischial_line",
        id:1111,
        answer:-1
    })

    const [quesionFour, setQuesionFour] = useState({
        label: "Medial to ilioischial line",
        slug: "medial_to_ilioischial_line",
        id:1112,
        answer:-1
    })

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [showQ2Modal, setShowQ2Modal] = useState(false)
    const [showQ2ModalNo, setShowQ2ModalNo] = useState(false)

    const [currentTitle, setCurrentTitle] = useState("Acetabular Revision")

    const [showMinimalModal, setShowMinimalModal] = useState(false)
    const [showApproaching2Cm, setShowApproaching2Cm] = useState(false)
    const [show2Cm, setShow2Cm] = useState(false)

    const [showModal41, setShowModal41] = useState(false)
    const [showModal42, setShowModal42] = useState(false)
    const [showModal43, setShowModal43] = useState(false)
    const [showModal44, setShowModal44] = useState(false)

    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>

            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>
                
                {currentQuestion==0 && (
                    <QItem allowButton={true} label={quesionOne.label} key={quesionOne.id} answer={quesionOne.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            
                            setCurrentTitle("Socket position & Ilioischial Line")
                            setCurrentQuestion(2)
                            window.initQuestionOneImagePopup(".popup-btn")

                            setQHistory( pushArr(qHistory, 2) )

                        }else{
                            // no then show question two
                            setCurrentQuestion(1)
                            setQHistory( pushArr(qHistory, 1) )
                        }
                    }} />
                )}

                {/* START IF SELECT NO IN QUESTION 1 */}

                {currentQuestion==1 && (
                    <QItem allowButton={true} label={quesionTwo.label} key={quesionTwo.id} answer={quesionTwo.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            // yes then show popup
                            setQuesionTwo(prev=>({
                                ...prev,
                                answer: 1
                            }))
                            setShowQ2Modal(true)
                        }else{
                            setQuesionTwo(prev=>({
                                ...prev,
                                answer: 0
                            }))
                            setShowQ2ModalNo(true)
                            
                        }
                    }} />
                )}

                {/* Question one answer yes then show this modal */}
                <Modal show={showQ2Modal} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowQ2Modal(!showQ2Modal)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="2" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question

                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesionTwo.label,
                                        "label_slug":quesionTwo.slug,
                                        "answer":1,
                                        "rhcc":2
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* END IF SELECT NO IN QUESTION 1 */}

                {/* Question one answer no then show this modal */}
                <Modal show={showQ2ModalNo} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowQ2ModalNo(!showQ2ModalNo)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="1" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question

                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesionTwo.label,
                                        "label_slug":quesionTwo.slug,
                                        "answer":0,
                                        "rhcc":1
                                    }
    
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* END IF SELECT NO IN QUESTION 1 */}

                {/* START IF SELECT YES IN QUESTION 1 */}

                    {currentQuestion==2 && (
                        <>
                            <QItem allowButton={false} label={quesionThree.label} key={quesionThree.id} answer={quesionThree.answer} onChange={(isChecked)=>{
                                setCurrentQuestion(3)
                                setQHistory( pushArr(qHistory, 3) )
                                window.initQuestionOneImagePopup(".popup-btn-2")
                            }} />

                            <QItem allowButton={false} label={quesionFour.label} key={quesionFour.id} answer={quesionFour.answer} onChange={(isChecked)=>{
                                setCurrentQuestion(4)
                                setQHistory( pushArr(qHistory, 4) )
                                window.initQuestionOneImagePopup(".popup-btn-3")
                            }} />

                            <hr />

                            <div className="portfolio-item row">
                                <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                    <label>Lateral to ilioischial line</label><br />
                                    <a href="/images/q11.png" className="fancylight popup-btn" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q11.png" alt="" />
                                    </a>
                                </div>
                                <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                    <label>Medial to ilioischial line</label><br />
                                    <a href="/images/q12.png" className="fancylight popup-btn" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q12.png" alt="" />
                                    </a>
                                </div>
                                <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                    <label>Medial to ilioischial line</label><br />
                                    <a href="/images/q13.png" className="fancylight popup-btn" data-fancybox-group="light">
                                        <img className="img-fluid" src="/images/q13.png" alt="" />
                                    </a>
                                </div>
                            </div>

                        </>
                    )}

                    {currentQuestion==3 && (
                        <>
                            <strong>Vertical migration and ischial lysis</strong><br /><br />

                            <table className="table tbl-bordered">
                                <tr>
                                    <td width="50%">
                                        VERTICAL MIGRATION
                                    </td>
                                    <td width="50%">
                                        ISCHIAL LYSIS
                                    </td>
                                </tr>
                            </table>

                            <Card2ColumnItem onClick={(e)=>{
                                setShowMinimalModal(true)
                            }} label="Minimal" label2="Minimal" />

                            <Card2ColumnItem onClick={(e)=>{
                                setShowApproaching2Cm(true)
                            }} label="Approaching 2cm" label2="Minimal" />

                            <Card2ColumnItem onClick={(e)=>{
                                setShow2Cm(true)
                            }} label=">2cm" label2="Mild/Moderate" />

                            <hr />

                            <div className="portfolio-item row">
                                <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                    <label>Minimal - Minimal</label><br />
                                    <a href="/images/q14.png" className="fancylight popup-btn-2" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q14.png" alt="" />
                                    </a>
                                </div>
                                <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                    <label>&gt;2cm - Mild/Moderate</label><br />
                                    <a href="/images/q15.png" className="fancylight popup-btn-2" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q15.png" alt="" />
                                    </a>
                                </div>
                            </div>

                        </>
                    )}

                    <Modal show={showMinimalModal} centered className="">
                        <button type="button" class="close" onClick={(e)=>setShowMinimalModal(!showMinimalModal)} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <Modal.Body>
                            <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="I" label2="RHCC" val2="1" />

                            <div className="propsbtn">
                                <a onClick={(e)=> {
                                    e.preventDefault()
                                    // move to next main question
                                    props.onEnd && props.onEnd(qHistory, [
                                        {
                                            "label":quesionOne.label,
                                            "label_slug":quesionOne.slug,
                                            "answer":1
                                        },
                                        {
                                            "label":quesionThree.label,
                                            "label_slug":quesionThree.slug,
                                            "answer":-1
                                        },
                                        {
                                            "label":"Minimal Minimal",
                                            "label_slug":[{
                                                    "key":"vertical_migration",
                                                    "val":"Minimal"
                                                },{
                                                    "key":"ischial_lysis",
                                                    "val":"Minimal"
                                                }],
                                            "answer":-1,
                                            "pg":"I",
                                            "rhcc":1
                                        }
                                    ])

                                }} href="">Next</a>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showApproaching2Cm} centered className="">
                        <button type="button" class="close" onClick={(e)=>setShowApproaching2Cm(!showApproaching2Cm)} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <Modal.Body>
                            
                            <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIB" label2="RHCC" val2="2" />

                            <div className="propsbtn">
                                <a onClick={(e)=> {
                                    e.preventDefault()
                                    // move to next main question

                                    props.onEnd && props.onEnd(qHistory, [
                                        {
                                            "label":quesionOne.label,
                                            "label_slug":quesionOne.slug,
                                            "answer":1
                                        },
                                        {
                                            "label":quesionThree.label,
                                            "label_slug":quesionThree.slug,
                                            "answer":-1
                                        },
                                        {
                                            "label":"Approaching 2cm Minimal",
                                            "label_slug":[{
                                                "key":"vertical_migration",
                                                "val":"Approaching 2cm"
                                            },{
                                                "key":"ischial_lysis",
                                                "val":"Minimal"
                                            }],
                                            "answer":-1,
                                            "pg":"IIB",
                                            "rhcc":2
                                        }
                                    ])
                                }} href="">Next</a>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={show2Cm} centered className="">
                        <button type="button" class="close" onClick={(e)=>setShow2Cm(!show2Cm)} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <Modal.Body>
                            
                            <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIIa" label2="RHCC" val2="3" />

                            <div className="propsbtn">
                                <a onClick={(e)=> {
                                    e.preventDefault()
                                    // move to next main question
                                    props.onEnd && props.onEnd(qHistory, [
                                        {
                                            "label":quesionOne.label,
                                            "label_slug":quesionOne.slug,
                                            "answer":1
                                        },
                                        {
                                            "label":quesionThree.label,
                                            "label_slug":quesionThree.slug,
                                            "answer":-1
                                        },
                                        {
                                            "label":"2cm Mild/Moderate",
                                            "label_slug":[{
                                                "key":"vertical_migration",
                                                "val":">2cm"
                                            },{
                                                "key":"ischial_lysis",
                                                "val":"Mild/Moderate"
                                            }],
                                            "answer":-1,
                                            "pg":"IIIa",
                                            "rhcc":3
                                        }
                                    ])

                                }} href="">Next</a>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {currentQuestion==4 && (
                        <>
                            <strong>Vertical migration, ischial lysis and tear drop</strong><br /><br />

                            <table className="table tbl-bordered">
                                <tr>
                                    <td width="33.33%">VERTICAL MIG</td>
                                    <td width="33.33%">IISCHIAL LYSIS</td>
                                    <td width="33.33%">TEAR DROP</td>
                                </tr>
                            </table>

                            <Card3ColumnItem onClick={(e)=>{
                                setShowModal41(true)
                            }} label="Minimal" label2="Minimal" label3="Intact" />

                            <Modal show={showModal41} centered className="">
                                <button type="button" class="close" onClick={(e)=>setShowModal41(!showModal41)} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <Modal.Body>
                                    
                                    <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIa" label2="RHCC" val2="1" />

                                    <div className="propsbtn">
                                        <a onClick={(e)=> {
                                            e.preventDefault()
                                            // move to next main question
                                            props.onEnd && props.onEnd(qHistory, [
                                                {
                                                    "label":quesionOne.label,
                                                    "label_slug":quesionOne.slug,
                                                    "answer":1
                                                },
                                                {
                                                    "label":quesionFour.label,
                                                    "label_slug":quesionFour.slug,
                                                    "answer":-1
                                                },
                                                {
                                                    "label":"Minimal Minimal Intact",
                                                    "label_slug":[{
                                                        "key":"vertical_migration",
                                                        "val":"Minimal"
                                                    },{
                                                        "key":"ischial_lysis",
                                                        "val":"Minimal"
                                                    },{
                                                        "key":"tear_drop",
                                                        "val":"Intact"
                                                    }],
                                                    "answer":-1,
                                                    "pg":"IIa",
                                                    "rhcc":1
                                                }
                                            ])

                                        }} href="">Next</a>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card3ColumnItem onClick={(e)=>{
                                setShowModal42(true)
                            }} label="< 2cm" label2="Minimal" label3="Violated" />

                            <Modal show={showModal42} centered className="">
                                <button type="button" class="close" onClick={(e)=>setShowModal42(!showModal42)} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <Modal.Body>
                                    
                                    <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIc" label2="RHCC" val2="2" />
                                    
                                    <div className="propsbtn">
                                        <a onClick={(e)=> {
                                            e.preventDefault()
                                            // move to next main question
                                            props.onEnd && props.onEnd(qHistory, [
                                                {
                                                    "label":quesionOne.label,
                                                    "label_slug":quesionOne.slug,
                                                    "answer":1
                                                },
                                                {
                                                    "label":quesionFour.label,
                                                    "label_slug":quesionFour.slug,
                                                    "answer":-1
                                                },
                                                {
                                                    "label":"< 2cm Minimal Violated",
                                                    "label_slug":[{
                                                        "key":"vertical_migration",
                                                        "val":"< 2cm"
                                                    },{
                                                        "key":"ischial_lysis",
                                                        "val":"Minimal"
                                                    },{
                                                        "key":"tear_drop",
                                                        "val":"Violated"
                                                    }],
                                                    "answer":-1,
                                                    "pg":"IIc",
                                                    "rhcc":2
                                                }
                                            ])

                                        }} href="">Next</a>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card3ColumnItem onClick={(e)=>{
                                setShowModal43(true)
                            }} label="> 2cm" label2="Severe" label3="Violated" />

                            <Modal show={showModal43} centered className="">
                                <button type="button" class="close" onClick={(e)=>setShowModal43(!showModal43)} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <Modal.Body>
                                    
                                    <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIIb" label2="RHCC" val2="3" />

                                    <div className="propsbtn">
                                        <a onClick={(e)=> {
                                            e.preventDefault()
                                            // move to next main question
                                            props.onEnd && props.onEnd(qHistory, [
                                                {
                                                    "label":quesionOne.label,
                                                    "label_slug":quesionOne.slug,
                                                    "answer":1
                                                },
                                                {
                                                    "label":quesionFour.label,
                                                    "label_slug":quesionFour.slug,
                                                    "answer":-1
                                                },
                                                {
                                                    "label":"> 2cm Severe Violated",
                                                    "label_slug":[{
                                                            "key":"vertical_migration",
                                                            "val":"> 2cm"
                                                        },{
                                                            "key":"ischial_lysis",
                                                            "val":"Severe"
                                                        },{
                                                            "key":"tear_drop",
                                                            "val":"Violated"
                                                        }],
                                                    "answer":-1,
                                                    "pg":"IIIb",
                                                    "rhcc":3
                                                }
                                            ])

                                        }} href="">Next</a>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card1ColumnItem onClick={(e)=>{
                                setShowModal44(true)
                            }} label="Evidence of pelvic discontinuity" />

                            <Modal show={showModal44} centered className="">
                                <button type="button" class="close" onClick={(e)=>setShowModal44(!showModal44)} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <Modal.Body>
                                    
                                    <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="Pelvic discontinuity" label2="RHCC" val2="3" />

                                    <div className="propsbtn">
                                        <a onClick={(e)=> {
                                            e.preventDefault()
                                            // move to next main question
                                            props.onEnd && props.onEnd(qHistory, [
                                                {
                                                    "label":quesionOne.label,
                                                    "label_slug":quesionOne.slug,
                                                    "answer":1
                                                },
                                                {
                                                    "label":quesionFour.label,
                                                    "label_slug":quesionFour.slug,
                                                    "answer":-1
                                                },
                                                {
                                                    "label":"Evidence of pelvic discontinuity",
                                                    "label_slug":"evidence_of_pelvic_discontinuity",
                                                    "answer":1,
                                                    "pg":"Pelvic discontinuity",
                                                    "rhcc":3
                                                }
                                            ])

                                        }} href="">Next</a>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <hr />

                            <div className="portfolio-item row">
                                <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                    <label>Minimal - Minimal</label><br />
                                    <a href="/images/q14.png" className="fancylight popup-btn-3" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q14.png" alt="" />
                                    </a>
                                </div>
                                <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                    <label>&gt;2cm - Severe</label><br />
                                    <a href="/images/q15.png" className="fancylight popup-btn-3" data-fancybox-group="light"> 
                                        <img className="img-fluid" src="/images/q15.png" alt="" />
                                    </a>
                                </div>
                            </div>

                        </>
                    )}

                {/* END IF SELECT YES IN QUESTION 1 */}

            </CardSection>
        </>
    )

}

export default QuestionOne