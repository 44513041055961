import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Card1ColumnItem from "./home/Card1ColumnItem"
import QItem from "./home/QItem"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const Question3 = (props) =>{

    const [quesionOne, setQuestionOne] = useState({
        label: "Infection present:",
        slug:"infection_present",
        id:31,
        answer:-1
    })

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Infection")

    const [showModal11, setShowModal11] = useState(false)
    const [showModal12, setShowModal12] = useState(false)
    const [showModal13, setShowModal13] = useState(false)

    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>
            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>

                {(currentQuestion==0 || currentQuestion==1) && (
                    <QItem allowButton={true} label={quesionOne.label} key={quesionOne.id} answer={quesionOne.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            // show next question with current one
                            setCurrentQuestion(1)
                            setQHistory( pushArr(qHistory, 1) )
                        }else{
                            // move to next main question
                            props.onEnd && props.onEnd(qHistory,[
                                {
                                    "label":"Infection present:",
                                    "label_slug":"infection_present",
                                    "answer":0
                                }
                            ])
                        }
                    }} />
                )}

                {currentQuestion==1 && (
                    <>
                        <Card1ColumnItem onClick={(e)=>{
                            setShowModal11(true)
                        }} label="1st Revision for Infection" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowModal12(true)
                        }} label="Re-revision for infection or atypical infection (TB, fungal, resistant)" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowModal13(true)
                        }} label="DAIR" />

                        {/* 1st Revision for Infection */}
                        <Modal show={showModal11} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModal11(!showModal11)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable className="cltable" label="RHCC" val="2" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":"Infection present:",
                                                "label_slug":"infection_present",
                                                "answer":1
                                            },
                                            {
                                                "label":"1st Revision for Infection",
                                                "label_slug":"1st_revision_for_infection",
                                                "answer":-1,
                                                "rhcc":2
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* Re-revision for infection or atypical infection (TB, fungal, resistant) */}
                        <Modal show={showModal12} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModal12(!showModal12)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable className="cltable" label="RHCC" val="3" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":"Infection present:",
                                                "label_slug":"infection_present",
                                                "answer":1
                                            },
                                            {
                                                "label":"Re-revision for infection or atypical infection (TB, fungal, resistant)",
                                                "label_slug":"re-revision_for_infection_or_at_typical_infection",
                                                "answer":-1,
                                                "rhcc":3
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* DAIR */}
                        <Modal show={showModal13} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModal13(!showModal13)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable className="cltable" label="RHCC" val="2" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":"Infection present:",
                                                "label_slug":"infection_present",
                                                "answer":1
                                            },
                                            {
                                                "label":"DAIR",
                                                "label_slug":"dair",
                                                "answer":-1,
                                                "rhcc":2
                                            }
                                        ])
                                        
                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                )}

                </CardSection>
        </>
    )

}

export default Question3