import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Card1ColumnItem from "./home/Card1ColumnItem"
import QItem from "./home/QItem"
import { BackButton, getLastArr, pushArr, removeArr } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const Question2 = (props) =>{

    const [quesionOne, setQuestionOne] = useState({
        label: "Loose stem and bone loss?",
        slug:"loose_stem_and_bone_loss",
        id:21,
        answer:-1
    })

    const [quesion2, setQuestion2] = useState({
        label: "Removal of well-fixed metaphyseal filling uncemented stem",
        slug:"removal_of_well-fixed_metaphyseal_filling_uncemented_stem",
        id:2101,
        answer:-1
    })

    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentTitle, setCurrentTitle] = useState("Femoral Revision")

    const [showModalQ1Yes, setShowModalQ1Yes] = useState(false)
    const [showModalQ1No, setShowModalQ1No] = useState(false)

    const [showModalQ21, setShowModalQ21] = useState(false)
    const [showModalQ22, setShowModalQ22] = useState(false)

    const [showModalQ31, setShowModalQ31] = useState(false)
    const [showModalQ32, setShowModalQ32] = useState(false)
    const [showModalQ33, setShowModalQ33] = useState(false)

    const [qHistory, setQHistory] = useState(props.history)

    useEffect(()=>{
        setCurrentQuestion( getLastArr(qHistory) )
    }, [])

    return(
        <>
            <BackButton onClick={(e)=>{

                if(currentQuestion==0){
                    // go back to prev. screen
                    props.onBack && props.onBack()
                }else{
                    // go back to prev. question
                    setQHistory( removeArr(qHistory) )

                    setCurrentQuestion( getLastArr(qHistory) )
                }

            }} />

            <CardSection label={currentTitle}>

                {(currentQuestion==0 || currentQuestion==1) && (
                    <QItem allowButton={true} label={quesionOne.label} key={quesionOne.id} answer={quesionOne.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            // show next question with title change
                            setCurrentTitle("Femoral Bone Loss")
                            setCurrentQuestion(2)
                            setQHistory( pushArr(qHistory, 2) )
                            window.initQuestionOneImagePopup(".popup-btn-q2")
                        }else{
                            // show next question but show current one
                            setCurrentQuestion(1)
                            setQHistory( pushArr(qHistory, 1) )
                        }
                    }} />
                )}

                {currentQuestion==1 && (
                    <QItem allowButton={true} label={quesion2.label} key={quesion2.id} answer={quesion2.answer} onChange={(isChecked)=>{
                        if(isChecked){
                            setShowModalQ1Yes(true)
                        }else{
                            setShowModalQ1No(true)
                        }
                    }} />
                )}

                {/* Question 2nd answer no then show this modal */}
                <Modal show={showModalQ1No} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowModalQ1No(!showModalQ1No)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="1" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesion2.label,
                                        "label_slug":quesion2.slug,
                                        "answer":0,
                                        "rhcc":1
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Question 2nd answer yes then show this modal */}
                <Modal show={showModalQ1Yes} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowModalQ1Yes(!showModalQ1Yes)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        
                        <ClassificationTable className="cltable" label="RHCC" val="3" />

                        <div className="propsbtn">
                            <a onClick={(e)=> {
                                e.preventDefault()
                                // move to next main question
                                props.onEnd && props.onEnd(qHistory, [
                                    {
                                        "label":quesionOne.label,
                                        "label_slug":quesionOne.slug,
                                        "answer":0
                                    },
                                    {
                                        "label":quesion2.label,
                                        "label_slug":quesion2.slug,
                                        "answer":1,
                                        "rhcc":3
                                    }
                                ])

                            }} href="">Next</a>
                        </div>
                    </Modal.Body>
                </Modal>

                {currentQuestion==2 && (
                    <>
                        <Card1ColumnItem onClick={(e)=>{
                            setShowModalQ21(true)
                        }} label="Minimal metaphyseal bone loss: metaphysis is supportive with intact diaphysis" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowModalQ22(true)
                        }} label="Metaphyseal bone loss extensive but still supportive with intact diaphysis" />

                        <Card1ColumnItem onClick={(e)=>{
                            setCurrentTitle("Diaphyseal bone")
                            setCurrentQuestion(3)
                            setQHistory( pushArr(qHistory, 3) )
                            window.initQuestionOneImagePopup(".popup-btn-q3")
                        }} label="Metaphysis severely damaged and NOT SUPPORTIVE" />

                        <hr />

                        <div className="portfolio-item row">
                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>Minimal metaphyseal bone loss, supportive</label><br />
                                <a href="/images/q21.png" className="fancylight popup-btn-q2" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q21.png" alt="" />
                                </a>
                            </div>
                            <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                <label>Extensive metaphyseal bone loss, still supportive</label><br />
                                <a href="/images/q22.png" className="fancylight popup-btn-q2" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q22.png" alt="" />
                                </a>
                            </div>
                            <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                <label>Metaphysis severely damaged and NOT SUPPORTIVE</label><br />
                                <a href="/images/q23.png" className="fancylight popup-btn-q2" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q23.png" alt="" />
                                </a>
                            </div>
                        </div>


                        {/* Minimal metaphyseal bone loss: metaphysis is supportive with intact diaphysis */}
                        <Modal show={showModalQ21} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModalQ21(!showModalQ21)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="I" label2="RHCC" val2="1" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Minimal metaphyseal bone loss: metaphysis is supportive with intact diaphysis",
                                                "label_slug":"minimal_metaphyseal_bone_loss:_metaphysis_is_supportive_with_intact_diaphysis",
                                                "answer":0,
                                                "pg":"I",
                                                "rhcc":1
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* Metaphyseal bone loss extensive but still supportive with intact diaphysis */}
                        <Modal show={showModalQ22} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModalQ22(!showModalQ22)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="II" label2="RHCC" val2="1" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Metaphyseal bone loss extensive but still supportive with intact diaphysis",
                                                "label_slug":"metaphyseal_bone_loss_extensive_but_still_supportive_with_intact_diaphysis",
                                                "answer":0,
                                                "pg":"II",
                                                "rhcc":1
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                )}

                {currentQuestion==3 && (
                    <>
                        <Card1ColumnItem onClick={(e)=>{
                            setShowModalQ31(true)
                        }} label=">4cm intact diaphysis available for fixation" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowModalQ32(true)
                        }} label="<4cm diaphyseal bone for fixation" />

                        <Card1ColumnItem onClick={(e)=>{
                            setShowModalQ33(true)
                        }} label="Extensive metaphyseal and diaphyseal damage in conjunction with widened femoral canal. The isthmus is non-supportive" />

                        <hr />

                        <div className="portfolio-item row">
                            <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
                                <label>&gt;4cm intact diaphysis</label><br />
                                <a href="/images/q24.png" className="fancylight popup-btn-q3" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q24.png" alt="" />
                                </a>
                            </div>
                            <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                <label>&lt;4cm diaphyseal bone</label><br />
                                <a href="/images/q25.png" className="fancylight popup-btn-q3" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q25.png" alt="" />
                                </a>
                            </div>
                            <div className="item gts col-lg-3 col-md-4 col-6 col-sm">
                                <label>Extensive metaphyseal and diaphyseal damage in conjunction with widened femoral canal</label><br />
                                <a href="/images/q26.png" className="fancylight popup-btn-q3" data-fancybox-group="light"> 
                                    <img className="img-fluid" src="/images/q26.png" alt="" />
                                </a>
                            </div>
                        </div>


                        {/* >4cm intact diaphysis available for fixation */}
                        <Modal show={showModalQ31} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModalQ31(!showModalQ31)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIIA" label2="RHCC" val2="2" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Metaphysis severely damaged and NOT SUPPORTIVE",
                                                "label_slug":"metaphysis_severely_damaged_and_not_supportive",
                                                "answer":-1
                                            },
                                            {
                                                "label":">4cm intact diaphysis available for fixation",
                                                "label_slug":"greaterthan_4cm intact_diaphysis_available_for_fixation",
                                                "answer":-1,
                                                "pg":"IIIA",
                                                "rhcc":2
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* <4cm diaphyseal bone for fixation */}
                        <Modal show={showModalQ32} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModalQ32(!showModalQ32)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IIIB" label2="RHCC" val2="3" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Metaphysis severely damaged and NOT SUPPORTIVE",
                                                "label_slug":"metaphysis_severely_damaged_and_not_supportive",
                                                "answer":-1
                                            },
                                            {
                                                "label":"<4cm diaphyseal bone for fixation",
                                                "label_slug":"lessthan_4cm diaphyseal_bone_for_fixation",
                                                "answer":-1,
                                                "pg":"IIIB",
                                                "rhcc":3
                                            }
                                        ])

                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* Extensive metaphyseal and diaphyseal damage in conjunction with widened femoral canal. The isthmus is non-supportive */}
                        <Modal show={showModalQ33} centered className="">
                            <button type="button" class="close" onClick={(e)=>setShowModalQ33(!showModalQ33)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal.Body>
                                
                                <ClassificationTable col="2" className="cltable" label="PAPROSKY GRADE" val="IV" label2="RHCC" val2="3" />

                                <div className="propsbtn">
                                    <a onClick={(e)=> {
                                        e.preventDefault()
                                        // move to next main question
                                        props.onEnd && props.onEnd(qHistory, [
                                            {
                                                "label":quesionOne.label,
                                                "label_slug":quesionOne.slug,
                                                "answer":1
                                            },
                                            {
                                                "label":"Metaphysis severely damaged and NOT SUPPORTIVE",
                                                "label_slug":"metaphysis_severely_damaged_and_not_supportive",
                                                "answer":-1
                                            },
                                            {
                                                "label":"Extensive metaphyseal and diaphyseal damage in conjunction with widened femoral canal. The isthmus is non-supportive",
                                                "label_slug":"extensive_metaphyseal_and_diaphyseal_damage_in_conjunction_with_widened_femoral_canal-the_isthmus_is_non-supportive",
                                                "answer":-1,
                                                "pg":"IV",
                                                "rhcc":3
                                            }
                                        ])
                                        
                                    }} href="">Next</a>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                )}

                </CardSection>
        </>
    )

}

export default Question2