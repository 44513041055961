const Card1ColumnItem = (props) =>{

    return(
        <div onClick={props.onClick} className="card nopad rhcc-card-column">
            <div className="card-body">
                <table className="table nomargin tbl-centered tbl-colored">
                    <tr>
                        <td width="100%">{props.label || ""}</td>
                    </tr>
                </table>
            </div>
        </div>
    )

}

export default Card1ColumnItem