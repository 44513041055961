const NavigationBar = () =>{

    return(
        <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
					<img src="/images/logo.svg" />
				</a>
            </div>
        </nav>
    )

}

export default NavigationBar;