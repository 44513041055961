
const ClassificationTable = (props) =>{

    return(
        props.col && props.col==2 ? (
            <div class={`card nopad ${props.className || ""}`}>
                <div class="card-body nopad colored">
                    <table class="tbl full-width">
                        <tr>
                            <td class="tth text-center" width="50%">{props.label || ""}</td>
                            <td class="tth text-center" width="50%">{props.label2 || ""}</td>
                        </tr>
                        <tr>
                            <td class="text-center">{props.val || ""}</td>
                            <td class="text-center">{props.val2 || ""}</td>
                        </tr>
                    </table>
                </div>
            </div>
        ) : (
            <div class={`card nopad ${props.className || ""}`}>
                <div class="card-body nopad colored">
                    <table class="tbl full-width">
                        <tr>
                            <td class="tth text-center" width="100%">{props.label || ""}</td>
                        </tr>
                        <tr>
                            <td class="text-center">{props.val || ""}</td>
                        </tr>
                    </table>
                </div>
            </div>
        )
    )

}

export default ClassificationTable