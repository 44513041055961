import { useRef } from "react";
import ExtRadios from "../libs/ExtRadios";

const QItem = (props) =>{

    return(
        <div onClick={(e)=> !props.allowButton && props.onChange && props.onChange(true)} className="card">
            <div className={`qlabel ${!props.allowButton ? "w100" : ""}`}>
                <span className="qtitle">{props.label || ""}</span>
            </div>
            
            {props.allowButton && (
                <ExtRadios answer={props.answer} onChange={props.onChange} />
            )}

        </div>
    )

}

export default QItem;