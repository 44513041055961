import axios from "axios"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { BackButton } from "./libs/BackButton"
import CardSection from "./libs/CardSection"
import ClassificationTable from "./libs/ClassificationTable"

const Report = (props) =>{

    const [totalRHCC, setTotalRHCC] = useState(0)
    const [accordianState, setAccordianState] = useState(true)
    const [showModalSaveReport,setShowModalSaveReport] = useState(false)

    useEffect(()=>{
        let rhccArr = [];
        
        props.data && props.data.map((question, index)=>(
            question.sub && question.sub.map((subq, sind)=>{
                if(subq.hasOwnProperty("rhcc"))
                    rhccArr.push(parseInt(subq.rhcc))
            })
        ))

        if(rhccArr.length>0)
            setTotalRHCC( Math.max(...rhccArr) )
        else
            setTotalRHCC(0)

    }, [])

    const getClassification = (questionRhccLabel, subq) =>{

        if(subq.hasOwnProperty("pg") && subq.hasOwnProperty("rhcc")){
            return <li>
                        <ClassificationTable col="2" label="PAPROSKY GRADE" label2={`${questionRhccLabel} RHCC`} val={subq.pg} val2={subq.rhcc} />
                </li>
        }
        
        if(subq.hasOwnProperty("uc") && subq.hasOwnProperty("rhcc")){
            return <li>
                        <ClassificationTable col="2" label="UNIFIED CLASSIFICATION" label2={`${questionRhccLabel} RHCC`} val={subq.uc} val2={subq.rhcc} />
                </li>
        }
        
        if(subq.hasOwnProperty("pg")){
            return <li>
                <ClassificationTable label="PAPROSKY GRADE" val={subq.pg} />
            </li>
        }

        if(subq.hasOwnProperty("rhcc")){
            return <li>
                <ClassificationTable label={`${questionRhccLabel} RHCC`} val={subq.rhcc} />
            </li>
        }

        if(subq.hasOwnProperty("uc")){
            return <li>
                <ClassificationTable label="UNIFIED CLASSIFICATION" val={subq.uc} />
            </li>
        }

    }

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [msg, setMsg] = useState("")
    const [email, setEmail] = useState("")
    const [identifier, setIdentifier] = useState("")

    const sendReport = (e) =>{

        e.preventDefault()

        setIsError(false)
        setMsg("")

        if(!email){
            setIsError(true)
            setMsg("Please enter email address")
            return
        }

        setIsLoading(true)

        let formData = new FormData()
        formData.append("email", email)
        formData.append("identifier", identifier)
        formData.append("total_rhcc", totalRHCC)
        formData.append("asa_grade_type", props.asaGradeType)
        formData.append("data", JSON.stringify(props.data))
        
        axios.post("https://rhcc.britishhipsociety.com/api/api.php",formData).then((response)=>{

            setIsLoading(false)

            if(response.status==200){
                setIsError(false)
                
                let json = response.data

                if(json.status=="RC200"){
                    setIsError(false)
                    setMsg(json.message)

                    setEmail("")
                    setIdentifier("")
                    
                }else{
                    setIsError(true)
                    setMsg(json.message)
                }

            }else{
                setIsError(true)
                setMsg("Something is wrong, please try again...")
            }

        }).catch((error)=>{
            setIsLoading(false)
            setIsError(true)
            setMsg("Something is wrong, please try again...")
        });

    }

    return(
        <>
            <BackButton onClick={(e)=>{
                props.onBack && props.onBack()
            }} />

            <CardSection label="Report">

                <ClassificationTable col="2" label="Overall RHCC" val={totalRHCC==0 ? "-" : `${totalRHCC}${props.asaGradeType==1 ? "*" : ""}`} label2="ASA Grade" val2={props.asaGradeType==1 ? ["ASA 3 or 4", <sup>1</sup>] : "ASA 1 or 2"} />

                <br />

                <div class="accordion" id="accordionExample">

                    <div class="accordion-item">
                        <h2 onClick={(e)=> {
                            setAccordianState(!accordianState)
                        }} class="accordion-header" id="headingOne">
                            <button class={`accordion-button ${accordianState ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={`${accordianState? "true" : "false"}`} aria-controls="collapseOne">
                            Full Report
                            </button>
                            <hr style={{margin:"0px"}} />
                        </h2>
                        <div id="collapseOne" class={`accordion-collapse collapse ${ accordianState ? "show" : ""}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                            
                            <ul class="results">
                                
                                {props.data && props.data.map((question, index)=>(
                                    <>
                                        <li key={`report-question-${index}`}>
                                            
                                            <div class="lftrgt">
                                                <div class="lft">
                                                    <strong class="mtdb">{question.question}</strong>
                                                </div>
                                                <div class="rgt">
                                                    <label class="btns active">Yes</label>
                                                </div>
                                            </div>
                                        </li>
                                        {question.sub && question.sub.map((subq, sind)=>(
                                            <>
                                                <li>
                                                    {subq.answer==-1 ? subq.label : (
                                                        <div class="lftrgt">
                                                            <div class="lft">
                                                                <div class="mtdb">{subq.label}</div>
                                                            </div>
                                                            <div class="rgt">
                                                                <label class="btns-noshadow">{subq.answer==1 ? "Yes" : "No"}</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                    
                                                </li>

                                                {getClassification(question.questionRhcc, subq)}

                                            </>
                                        ))}
                                        
                                    </>
                                ))}

                            
                            </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <br />

                <button onClick={(e)=>{
                    setShowModalSaveReport(true)
                }} className="btn btn-primary btn-block">Save Report</button>

                <Modal show={showModalSaveReport} centered className="">
                    <button type="button" class="close" onClick={(e)=>setShowModalSaveReport(!showModalSaveReport)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <Modal.Body>
                        <br />
                        
                        <input value={email} onChange={(e)=> setEmail(e.target.value) } type="text" className="form-control" placeholder="Enter email address" />

                        <br />

                        <input value={identifier} onChange={(e)=> setIdentifier(e.target.value) } type="text" className="form-control" placeholder="Enter identifier (if required)" />

                        <br />

                        {msg && (
                            <div className="col-md-12">
                                <div className={ isError ? "alert alert-danger mt-10" : "alert alert-success mt-10" } role="alert">
                                    { msg && msg.split("\n").map((str, index) => <p style={{"margin":"0px"}} key={`px-${index}`}>{str}</p>)}
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button onClick={sendReport} disabled={isLoading} className="btn btn-primary">Get Report</button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>

                </CardSection>
        </>
    )

}

export default Report