import { useState } from "react";

const ExtRadios = (props) =>{

    const [checked, setChecked] = useState(props.answer)

    return(
        <div className="bhs-buttons">
            <label className={`${checked==1 ? "active" : ""}`} >
                <input onChange={(e)=> {
                    e.target.checked && setChecked(1)
                    props.onChange && props.onChange(1)
                }} defaultChecked={checked==1} type="radio" name="ar" /> <span className="vertical-center">Yes</span>
            </label>
            <label onChange={(e)=> {
                    e.target.checked && setChecked(0)
                    props.onChange && props.onChange(0)
                }} className={`${checked==0 ? "active" : ""}`}>
                <input defaultChecked={checked==0} type="radio" name="ar" /> <span className="vertical-center">No</span>
            </label>
        </div>
    )

}

export default ExtRadios;