
const BackButton = (props) =>{

    return(
        <div className="nav-breadcrumb">
            <a className="back" onClick={(e)=>{
                e.preventDefault()
                props.onClick && props.onClick(e)
            }} href="">Back</a>
        </div>
    )

}

const pushArr = (arr, val) =>{
    arr.push(val)
    return arr
}

const removeArr = (arr) =>{
    arr.pop()
    return arr
}

const getLastArr = (arr) =>{

    if(arr.length==0) return 0
    
    return arr.at(-1)
}

export {
    BackButton,
    pushArr,
    removeArr,
    getLastArr
}